




















































































































// @ is an alias to /src

import { MutationPayload } from 'vuex';
import Modal from '@/components/modal.vue';
import Input from '@/components/form/input.vue';
import {
  VALIDATION_ERRORS,
  VALIDATION_FIELD_ERRORS,
  OTHER_ERRORS,
  extractErrorMessages,
} from '@/utils/extractErrorMessages';
import DatePicker from '@/components/form/date-picker.vue';
import Textarea from '@/components/form/textarea.vue';
import Select from '@/components/form/select.vue';
import store from '@/store';
import router from '@/router';
import ColorPicker from '@/components/form/color-picker.vue';
import Checkbox from '@/components/form/checkbox.vue';
import { IDatePicker, IInput } from '@/components/form';
import { createSessionChat } from '@/api/sessions';
import { uploadImage } from '@/api/files';
import Cropper from '@/components/form/cropper.vue';
import { form, INewSessionInputs, inputs, modal, sessionLanguages } from '../index';
import { defineComponent } from '@vue/composition-api';
import useContext from '@/composition/context';
export default defineComponent({
  components: {
    Modal,
    Input,
    Textarea,
    DatePicker,
    ColorPicker,
    Checkbox,
    Cropper,
    Select,
  },
  data() {
    return {
      modal: { ...modal },
      modalCropper: { isActive: false, isCard: true },
      form: form,
      inputs: inputs(),
      image: {} as File,
      event: 0,
      exhibitor: 0,
      loading: false,
      languageVariation: '',
      sessionLanguages: sessionLanguages,
      cropper: {
        image: '',
        onSelect: (blob: string) => {
          console.log(blob);
        },
        aspectRatio: 16 / 9,
      },
      cropping: true,
      croppedFileName: '',
      confirming: false,
      cropSrc: '',
    };
  },
  setup() {
    const { translations } = useContext();
    return { translations };
  },

  created() {
    this.event = parseInt(this.$route.params.event, 10);
    this.exhibitor = parseInt(this.$route.params.exhibitor, 10);
    this.cropper.onSelect = this.onSelectCrop;
    if (this.inputs.image) {
      this.inputs.image.onFileSelect = this.selectImage;
    }
  },
  methods: {
    async create() {
      this.loading = true;
      let formInvalid = false;
      // Name, Start Date and End date are mandatory
      if (this.form.name === '') {
        this.inputs.name = {
          ...(this.inputs.name as IInput),
          message: this.translations.common.mandatory_field,
          error: true,
        };
        formInvalid = true;
      }
      if (!this.form.start_date || this.form.start_date === '') {
        this.inputs.start_date = {
          ...(this.inputs.start_date as IDatePicker),
          message: this.translations.common.mandatory_field,
          error: true,
        };
        formInvalid = true;
      } else {
        const date = new Date(this.form.start_date);
        this.form.start_date = date.toISOString();
      }

      if (!this.form.end_date || this.form.end_date === '') {
        this.inputs.end_date = {
          ...(this.inputs.end_date as IDatePicker),
          message: this.translations.common.mandatory_field,
          error: true,
        };
        formInvalid = true;
      } else {
        const date = new Date(this.form.end_date);
        this.form.end_date = date.toISOString();
      }

      if (this.form.external_link === '') {
        this.form.external_link = undefined;
        this.form.chat = false;
      }
      if (this.form._translations) {
        this.form.external_link = undefined;
        this.form.chat = false;
      }

      // Save session
      if (!formInvalid) {
        if (this.image.name) {
          const imageForm = new FormData();
          imageForm.append('file', this.image);

          const response = await uploadImage(imageForm);
          this.form.image = response.data.id;
        }
        store.dispatch('addSession', {
          type: 'exhibitor',
          payload: this.form,
          event: this.event,
          exhibitor: this.exhibitor,
        });
      } else {
        this.loading = false;
      }

      const unsubscribe = store.subscribe(async (mutation: MutationPayload) => {
        // Session saved successfully
        if (mutation.type === 'newSession') {
          this.loading = false;
          router.push(`/event/${this.event}/manage-exhibitor/${this.exhibitor}/sessions`);
          store.dispatch('fetchSessions', {
            event: this.event,
            type: 'exhibitor',
            exhibitor: this.exhibitor,
            page: 1,
            page_size: 1000,
          });
          if (
            this.form.embedded &&
            this.form.external_link &&
            this.form.external_link !== '' &&
            this.form.chat
          ) {
            await createSessionChat(mutation.payload.id);
            unsubscribe();
          }
        }

        // Session creation was not successful
        if (mutation.type === 'setSessionsError') {
          this.loading = false;
          const errorData = extractErrorMessages(mutation.payload.response.data);

          // Field validation errors
          for (const [key, value] of Object.entries(errorData[VALIDATION_FIELD_ERRORS])) {
            // Add warning to form fields
            // @ts-ignore
            const currentValue = this.form[key];

            this.inputs[key as keyof INewSessionInputs] = {
              ...this.inputs[key as keyof INewSessionInputs],
              message: value,
              error: true,
              value: currentValue,
            } as any;
          }

          // Display other errors
          const popupErrors = [errorData[VALIDATION_ERRORS], ...errorData[OTHER_ERRORS]];
          for (const value of popupErrors) {
            store.commit('addPopup', {
              message: value,
              type: 'danger',
              autohide: true,
            });
          }
          unsubscribe();
        }
      });
    },

    selectImage(file: File) {
      this.croppedFileName = file.name;
      if (file.name.match(/.(jpg|jpeg|png|gif)$/i)) {
        const reader = new FileReader();
        reader.onload = (e) => {
          if (e && e.target && e.target.result) {
            this.cropper = { ...this.cropper, image: e.target.result as string };
            this.cropping = true;
            this.confirming = false;
            this.modalCropper.isActive = true;
          }
        };
        reader.readAsDataURL(file);
      } else {
        this.inputs.image = {
          ...this.inputs.image,
          value: '',
          message: this.translations.common.file_not_image,
          error: true,
        } as any;
        this.$forceUpdate();
      }
    },

    onSelectCrop(blob: string) {
      const file = new File([blob], this.croppedFileName, { type: 'image/png' });
      const urlCreator = window.URL || window.webkitURL;
      this.cropSrc = urlCreator.createObjectURL(blob);
      this.image = file;
      this.cropping = false;
      this.confirming = true;
    },

    confirmCrop() {
      this.modalCropper.isActive = false;
    },

    cancel() {
      this.modalCropper.isActive = false;
    },

    cancelCrop() {
      this.cropping = true;
      this.confirming = false;
    },
  },
});
